<template>
  <div class="task-item">
    <div class="task-item-content">
      <div class="task-tile">{{ taskItem.name || '--' }}</div>
      <div class="task-info-box">
        <div class="info-item">
          <div class="item-label">类型：</div>
          <div class="item-value">
            {{ taskItem.task_type_txt || '--' }}
          </div>
        </div>
        <van-count-down
          v-if="pActiveName === 'execute' && endTime > 0"
          :time="endTime"
          @finish="onTaskEnd"
        >
          <template #default="timeData">
            <span class="task-info-item">距离任务结束剩余&nbsp;</span>
            <span v-if="timeData.days">
              <span class="txt-blue weight500">{{ timeData.days }}</span>
              <span class="task-info-item">&nbsp;天&nbsp;&nbsp;</span>
            </span>
            <span v-if="timeData.hours">
              <span class="txt-blue weight500">{{ timeData.hours }}</span>
              <span class="task-info-item">&nbsp;时&nbsp;&nbsp;</span>
            </span>
            <span v-if="timeData.minutes">
              <span class="txt-blue weight500">{{ timeData.minutes }}</span>
              <span class="task-info-item">&nbsp;分&nbsp;&nbsp;</span>
            </span>
            <span v-if="timeData.seconds">
              <span class="txt-blue weight500">{{ timeData.seconds }}</span>
              <span class="task-info-item">&nbsp;秒</span>
            </span>
          </template>
        </van-count-down>
        <van-count-down
          v-if="pActiveName === 'no_start' && startTime > 0"
          :time="startTime"
          @finish="onTaskEnd"
        >
          <template #default="timeData">
            <span class="task-info-item">距离任务开始剩余&nbsp;</span>
            <span v-if="timeData.days">
              <span class="txt-blue weight500">{{ timeData.days }}</span>
              <span class="task-info-item">&nbsp;天&nbsp;&nbsp;</span>
            </span>
            <span v-if="timeData.hours">
              <span class="txt-blue weight500">{{ timeData.hours }}</span>
              <span class="task-info-item">&nbsp;时&nbsp;&nbsp;</span>
            </span>
            <span v-if="timeData.minutes">
              <span class="txt-blue weight500">{{ timeData.minutes }}</span>
              <span class="task-info-item">&nbsp;分&nbsp;&nbsp;</span>
            </span>
            <span v-if="timeData.seconds">
              <span class="txt-blue weight500">{{ timeData.seconds }}</span>
              <span class="task-info-item">&nbsp;秒</span>
            </span>
          </template>
        </van-count-down>
        <div v-else class="info-item">
          <div class="item-label">结束时间：</div>
          <div class="item-value">
            {{ dateFormate(taskItem.end_time_strtotime) }}
          </div>
        </div>
      </div>
      <!-- <div v-if="pActiveName !== 'ending'" class="time-text">
        距离任务结束剩余
        <span class="txt-blue">5</span>
        天
        <span class="txt-blue">2</span>
        时
        <span class="txt-blue">10</span>
        分
      </div> -->
    </div>
    <div class="task-operation">
      <van-button
        v-if="pActiveName === 'execute'"
        type="primary"
        size="small"
        color="#1773FA"
        @click="goTO"
      >
        去完成
      </van-button>
      <div
        v-else-if="pActiveName === 'no_start'"
        class="text-btn"
        @click="goTO"
      >
        查看
      </div>
      <div v-else-if="pActiveName === 'end'" class="status-text">
        {{ taskItem.execute_status_txt || '--' }}
      </div>
    </div>
  </div>
</template>
<script>
import { Button, CountDown, Dialog } from 'vant'
import { openEnterpriseChat } from '../../utils/nwechat'
import { checkIsFriend } from '../../service/wechat.service'
import moment from 'moment'
export default {
  name: 'TaskItem',
  components: {
    [Button.name]: Button,
    [CountDown.name]: CountDown
  },
  props: {
    pActiveName: {
      type: String,
      defualt() {
        return 'execute'
      }
    },
    taskItem: {
      type: Object,
      defualt() {
        return {}
      }
    },
    member_userid: {
      type: String,
      defualt() {
        return ''
      }
    },
    index: {
      type: Number,
      defualt() {
        return -1
      }
    },
    send_record_id: {
      type: Number
    },
    taskId: {
      type: Number
    }
  },
  watch: {
    pActiveName(val) {
      console.log(val)
    }
  },
  data() {
    return {
      endTime: 0,
      startTime: 0
      // ended: false
    }
  },
  mounted() {
    console.log('测试倒计时')
    this.endTime = Math.round(
      this.taskItem.end_time_strtotime * 1000 - new Date()
    )
    this.startTime = Math.round(
      this.taskItem.start_time_strtotime * 1000 - new Date()
    )
  },
  methods: {
    // async init() {
    //   // let userInfo = JSON.parse(localStorage.getItem('vuex'))
    //   // this.member_userid = userInfo.user.info.qw_userid

    //   this.externalUserIds = this.taskItem.receiver
    //   console.log('externalUserIds11111', this.externalUserIds)

    //   const { data } = await checkIsFriend({
    //     qw_userid: this.member_userid,
    //     qw_external_userid: this.externalUserIds
    //   })

    //   if (data.status) {
    //     // alert('当前客户好友关系')
    //     this.$nextTick(() => {
    //       console.log('this.initEntry()')
    //       this.openUserChart()
    //     })
    //   } else {
    //     Dialog.alert({
    //       title: '提示',
    //       message: '当前客户为非好友关系，请通过其他方式联系客户。'
    //     })
    //     // alert('当前客户为非好友关系，请通过其他方式联系客户。')
    //   }
    // },
    // // 去完成任务打开会话
    // async openUserChart() {
    //   console.log('externalUserIds2222222', this.taskItem.receiver)
    //   await openEnterpriseChat({
    //     userIds: this.member_userid,
    //     externalUserIds: this.taskItem.receiver
    //     // chatId: ''
    //   })
    // },

    // 查看任务，跳转对应引导页
    goTO() {
      this.$router.push({
        path: '/guidePage',
        query: {
          type: this.taskItem.task_type,
          id: this.send_record_id,
          userIds: this.member_userid,
          task_id: this.taskId
        }
      })
    },

    // 倒计时结束后
    onTaskEnd() {
      this.$emit('removeItem', this.index)
    },

    dateFormate(date) {
      date = date * 1000
      return moment(date).format('MM-DD HH:mm')
    }
  }
}
</script>

<style lang="less" scoped>
.task-item {
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  padding: 12px 15px;
  background-color: #fff;

  &-content {
    .task-tile {
      line-height: 24px;
      font-size: 16px;
      color: #000;
      font-weight: 500;
      // padding-bottom: 8px;
    }
  }

  .task-info-box {
    // display: flex;
    .info-item {
      margin-right: 12px;
      line-height: 24px;
      font-size: 12px;
      display: flex;
    }
    .item-label {
      color: #000;
    }
    .item-value {
      color: #999999;
    }
  }

  .task-info-item {
    font-size: 12px;
    color: #999999;
    font-weight: 300;
  }

  .time-text {
    margin-right: 12px;
    line-height: 24px;
    font-size: 12px;
    color: #999999;
  }

  .task-operation {
    display: flex;
    align-items: center;
    .text-btn {
      color: #1773fa;
      font-size: 12px;
    }
    .status-text {
      color: #c4c4c4;
      font-size: 12px;
    }
    :root {
      --van-button-small-height: 28px;
    }
  }
}
</style>

<style lang="less">
.task-item .task-operation {
  .van-button--small {
    height: 28px;
    .van-button__text {
      display: block;
      margin-top: 1px;
    }
  }
}
</style>
