<template>
  <div class="task-list-page">
    <van-tabs
      v-model:active="activeName"
      title-active-color="#1773FA"
      title-inactive-color="#000"
      background="#FAFAFA"
      color="#1773FA"
      line-width="48px"
      @change="onClickTab"
    >
      <van-tab
        v-for="(tabItem, tabIndex) in taskTabs"
        :title="tabItem.title"
        :name="tabItem.name"
        :key="tabIndex"
        title-class="task-tab-title"
      >
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model:loading="loading"
            v-model:error="error"
            :finished="finished"
            :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
            error-text="请求失败，点击重新加载"
            :immediate-check="false"
            @load="onload"
          >
            <TaskItem
              v-for="(taskTitem, index) in list"
              :taskItem="taskTitem"
              :key="taskTitem.id"
              :index="index"
              :send_record_id="taskTitem.id"
              :taskId="taskTitem.task_id"
              :member_userid="params.member_userid"
              :pActiveName.sync="activeName"
              @removeItem="onRemoveItem"
            ></TaskItem>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tabs, Tab, PullRefresh, List, Notify } from 'vant'
import TaskItem from './taskItem.vue'
import { getTaskList } from '../../service/task.service.js'
export default {
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Notify.Component.name]: Notify.Component,
    TaskItem: TaskItem
  },
  data() {
    const taskTabList = [
      {
        title: '待执行',
        name: 'execute'
      },
      {
        title: '未开始',
        name: 'no_start'
      },
      {
        title: '已结束',
        name: 'end'
      }
    ]
    return {
      activeName: 'execute',
      taskTabs: taskTabList,
      loading: false,
      list: [],
      finished: false,
      refreshing: false,
      error: false,
      params: {
        member_userid: '',
        status: 'execute',
        page: 1,
        page_size: 20
      },
      taskId: 0
    }
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('vuex'))
    // 需去除-----
    // this.params.member_userid = 'LiBin'
    this.params.member_userid = userInfo.user.info.qw_userid
    // this.onRefresh()
    // this.onload()
    this.onClickTab()
  },
  // watch: {
  //   $route(val, old) {
  //     this.onRefresh()
  //     // console.log(val, old)
  //     // alert(old.url)
  //   }
  // },
  methods: {
    // 获取列表信息
    async onload() {
      if (this.list.length > 0) {
        this.params.page += 1
      }
      // console.log('列表1------')
      try {
        this.loading = true
        const { data, code } = await getTaskList(this.params)
        if (code !== 200) {
          this.loading = false
          this.error = true
          throw code
          // return
        }
        if (data.list.length === 0) {
          this.loading = false
          this.finished = true
        }
        this.count = data.count || 0
        this.list = this.list.concat(data.list)
        console.log('列表2------', this.list)
        this.loading = false
        if (
          this.list.length < this.page_size ||
          this.count === this.list.length
        ) {
          this.finished = true
        }
      } catch (error) {
        // this.error = true
        console.log(error)
      }
    },

    async onRefresh() {
      this.list = []
      this.refreshing = true
      this.params.page = 1
      await this.onload()
      this.refreshing = false
    },
    async onClickTab() {
      this.$router.push({
        query: { id: this.activeName }
      })
      this.params.status = this.activeName
      this.list = []
      this.finished = false
      this.params.page = 1
      await this.onload()
    },
    onRemoveItem(index) {
      this.list.splice(index, 1)
    }
  }
}
</script>

<style lang="less">
.task-list-page .van-pull-refresh {
  min-height: calc(100vh - 75px);
}
// tabte页
.task-tab-title {
  height: 48px;
}
.task-list-page .van-tab--disabled {
  color: #c8c9cc !important;
}
</style>
